<template>
  <div class="NavbarMobile">
    <a class="logo" href="/"
      ><img src="../assets/Bilder/stavab-logoCropped.png"
    /></a>
    <div class="MenuBtns">
      <button @click="toggleDropdown">
        <img src="@/assets/icons/hamburger-menu-icon.png" />
      </button>
      <div class="Dropdown" :class="{ open: isMenuOpen }">
        <router-link class="btn" to="/" @click="toggleDropdown"
          >Hem</router-link
        >
        <router-link class="btn" to="/produkter" @click="toggleDropdown"
          >Produkter</router-link
        >
        <router-link class="btn" to="/kontakta" @click="toggleDropdown"
          >Kontakta</router-link
        >
      </div>
    </div>
  </div>
  <div class="NavBarDesktop">
    <a class="logo" href="/"
      ><img src="../assets/Bilder/stavab-logoCropped.png"
    /></a>
    <div class="MenuBtns">
      <router-link class="btn" to="/">Hem</router-link>
      <router-link class="btn" to="/produkter">Produkter</router-link>
      <router-link class="btn" to="/kontakta">Kontakta</router-link>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";

const isMenuOpen = ref(false);
const toggleDropdown = () => {
  isMenuOpen.value = !isMenuOpen.value;
};
</script>
<style lang="scss" scoped>
@import "../assets/variables.scss";

.NavBarDesktop {
  display: none;
}
.NavbarMobile {
  width: 100%;
  background-color: $light;
  display: flex;
  align-items: center;
  border-bottom: 2px solid $dark;
  padding: 0px 20px;
  height: 10vh;
  .logo {
    // width: 40%;
    img {
      width: 100%;
    }
  }

  .MenuBtns {
    position: relative;
    cursor: pointer;

    width: 100%;
    display: flex;
    justify-content: right;
    button {
      padding: 0;
      border: none;
      width: auto;
      display: flex;
      justify-content: right;

      img {
        width: 35px;
        background-color: $light;
      }
    }
  }

  .Dropdown {
    z-index: 10;
    position: absolute;
    top: 110%;
    right: 0;
    background-color: $primary;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 1);

    display: flex;
    flex-direction: column;
    width: 150px;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .Dropdown.open {
    width: 90%;
    opacity: 1;
    transform: scaleY(1);
  }

  .btn {
    padding: 15px;
    //border: 1px solid $light;
    text-decoration: none;
    color: $light;
    transition: background 0.2s;
  }
}
@media screen and (min-width: 768px) {
  .NavbarMobile {
    display: none;
  }
  .NavBarDesktop {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 50px;
    border-bottom: 2px solid $dark;
    height: 12vh;
    .logo {
      //width: 40%;
      img {
        width: 100%;
      }
    }
    .MenuBtns {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      .btn {
        padding: 10px 20px;

        text-decoration: none;
        color: $dark;

        -webkit-transition: color 200ms linear, background-color 300ms linear;
        -ms-transition: color 200ms linear, background-color 300ms linear;
        transition: color 200ms linear, background-color 300ms linear;
        &:hover {
          background-color: $primary;
          color: $light;
        }
      }
    }
  }
}
</style>
