<template>
  <div class="produkterMain">
    <div class="titelProd">
      <h1>Våra produkter</h1>
      <p>
        Allt du behöver för vatten- och avlopp! Hittar du inte vad du letar
        efter? Ring oss
        <a href="tel:046-25 55 80">046-25 55 80</a>
      </p>
    </div>
    <div class="grid" v-motion-fade-visible-once :duration="800" :delay="100">
      <div class="gridItem">
        <h3>Dränering</h3>

        <a class="pdfLink" href="/pdf/draneringsror.pdf" target="_blank">
          <img src="../assets/Bilder/dranering320.jpg" />
          <p class="PDF">Visa PDF</p>
        </a>
      </div>
      <div class="gridItem">
        <h3>Isodrän</h3>

        <a class="pdfLink" href="/pdf/isodran.pdf" target="_blank"
          ><img src="../assets/Bilder/isodran320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Brunnar</h3>

        <a class="pdfLink" href="/pdf/VA-brunnar.pdf" target="_blank"
          ><img src="../assets/Bilder/brunnar320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Betäckningar</h3>

        <a class="pdfLink" href="/pdf/betecking.pdf" target="_blank">
          <img src="../assets/Bilder/betackningar320.jpg" />
          <p class="PDF">Visa PDF</p>
        </a>
      </div>
      <div class="gridItem">
        <h3>Inomhusavlopp</h3>

        <a class="pdfLink" href="/pdf/inomhusavlopp.pdf" target="_blank"
          ><img src="../assets/Bilder/inomhus-1.png" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Enskilt avlopp</h3>

        <a class="pdfLink" href="/pdf/minireningsverk.pdf" target="_blank"
          ><img src="../assets/Bilder/enskilt320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Markavlopp</h3>

        <a class="pdfLink" href="/pdf/markavlopp.pdf" target="_blank">
          <img src="../assets/Bilder/markavlopp.jpg" />
          <p class="PDF">Visa PDF</p>
        </a>
      </div>
      <div class="gridItem">
        <h3>Kabelrör</h3>

        <a class="pdfLink" href="/pdf/kabelror.pdf" target="_blank"
          ><img src="../assets/Bilder/kabelror320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Fiberduk</h3>

        <a class="pdfLink" href="/pdf/Fiberdukar.pdf" target="_blank"
          ><img src="../assets/Bilder/fiverduk320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Markränna</h3>

        <a class="pdfLink" href="/pdf/markranna.pdf" target="_blank">
          <img src="../assets/Bilder/markranna320.jpg" />
          <p class="PDF">Visa PDF</p>
        </a>
      </div>
      <div class="gridItem">
        <h3>Tryckrör</h3>

        <a class="pdfLink" href="/pdf/Tryckror.pdf" target="_blank"
          ><img src="../assets/Bilder/tryckror320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Kopplingar</h3>

        <a class="pdfLink" href="/pdf/kopplingar.pdf" target="_blank"
          ><img src="../assets/Bilder/kopplingar320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Ventiler</h3>

        <a class="pdfLink" href="/pdf/ventilor.pdf" target="_blank">
          <img src="../assets/Bilder/ventiler320.jpg" />
          <p class="PDF">Visa PDF</p>
        </a>
      </div>
      <div class="gridItem">
        <!--Fixa pdf-->
        <h3>Pumpar</h3>

        <a class="pdfLink" href="/pdf/pumpstationer.pdf" target="_blank"
          ><img src="../assets/Bilder/pumpar320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Olje/Fett avskiljare</h3>

        <a class="pdfLink" href="/pdf/oljeavskiljare.pdf" target="_blank"
          ><img src="../assets/Bilder/oljeav320.jpg" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Regnvattensystem</h3>

        <a class="pdfLink" href="/pdf/regnvattenssystem.pdf" target="_blank"
          ><img src="../assets/Bilder/Regnvattensystem.png" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
      <div class="gridItem">
        <h3>Dagvatten</h3>

        <a class="pdfLink" href="/pdf/dagvatten.pdf" target="_blank"
          ><img src="../assets/Bilder/dagvatten.png" />
          <p class="PDF">Visa PDF</p></a
        >
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
@import "../assets/variables.scss";

.produkterMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .titelProd {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px 20px;

    a {
      color: black;
    }
  }
  .grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    .gridItem {
      aspect-ratio: 1 / 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-top: 20px;
      position: relative;
      width: 100%;
      h3 {
        margin: 0;
        padding-bottom: 5px;
      }
      .pdfLink {
        aspect-ratio: 1 / 1;
        width: 70%;
        display: block;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        img {
          aspect-ratio: 1 / 1;
          width: 100%;
          height: auto;
          object-fit: cover;
          display: block;

          height: auto;
        }
        .PDF {
          background-color: $dark;
          color: $light;
          padding: 10px 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .produkterMain {
    .titelProd {
      a {
        &:hover {
          color: $secondary;
        }
      }
    }
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      padding-bottom: 100px;
      .pdfLink {
        position: relative;
        overflow: hidden;
        -webkit-transition: box-shadow 200ms linear;
        -ms-transition: box-shadow 200ms linear;
        transition: box-shadow 200ms linear;
        &:hover {
          box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.4);
        }
        img {
          object-fit: scale-down;
        }
        .PDF {
          margin: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;

          color: white;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          padding: 10px;
          transition: opacity 0.3s ease, background-color 200ms linear;
          &:hover {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}
</style>
