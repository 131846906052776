import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProdukterView from "../views/ProdukterView.vue";
import KontaktaView from "@/views/KontaktaView.vue";

const routes = [
  {
    path: "/",
    name: "Hem · STAVAB",
    component: HomeView,
    meta: {
      description:
        "Välkommen till STAVAB – experter på dränering och avloppslösningar.",
    },
  },

  {
    path: "/produkter",
    name: "Produkter · STAVAB",
    component: ProdukterView,
    meta: {
      description:
        "Utforska vårt breda sortiment av produkter för avlopp och dränering.",
    },
  },
  {
    path: "/kontakta",
    name: "Kontakta · STAVAB",
    component: KontaktaView,
    meta: {
      description:
        "Kontakta STAVAB för rådgivning och professionell hjälp med ditt projekt.",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.name;
  const description = to.meta.description || "Standardbeskrivning för STAVAB.";

  const updateMetaTag = (name, content) => {
    let tag = document.querySelector(
      `meta[name="${name}"], meta[property="${name}"]`
    );
    if (tag) {
      tag.setAttribute("content", content);
    } else {
      tag = document.createElement("meta");

      tag.setAttribute("property", name);
      tag.setAttribute("content", content);
      document.head.appendChild(tag);
    }
  };

  // Uppdatera beskrivning

  updateMetaTag("description", description);

  // Open Graph (Facebook, LinkedIn)
  updateMetaTag("og:title", document.title);
  updateMetaTag("og:description", description);

  next();
});

export default router;
