<template>
  <div class="kontaktaMain">
    <div class="banner">
      <img src="../assets/Bilder/stavab-slider-2.jpg" />
    </div>
    <div
      class="contactWrapper"
      v-motion-fade-visible-once
      :duration="1000"
      :delay="100"
    >
      <div class="Contact">
        <div class="contactText">
          <p>Välkommen att</p>
          <h1>Kontakta oss</h1>
        </div>
        <div class="contactInfo">
          <div class="CIdiv">
            <img src="../assets/icons/attherate-icon.png" />
            <a href="mailto:info@stavab.se">info@stavab.se</a>
          </div>
          <div class="CIdiv">
            <img src="../assets/icons/phone-call-icon.png" />
            <a href="tel:046255580">046-25 55 80</a>
          </div>
          <div class="CIdiv">
            <img src="../assets/icons/maps-pin-black-icon.png" />
            <a
              class="adress"
              href="https://maps.app.goo.gl/5ejhJssx44EH8Xxu7"
              target="_blank"
              >Fabriksvägen 9, 245 34 Staffanstorp</a
            >
          </div>
        </div>
      </div>
      <div style="display: none" class="submitted">
        <h2>Tack för att du kontaktar oss!</h2>
        <p>Vi kommer återkomma så snabbt vi kan!</p>
      </div>
      <form ref="form" @submit.prevent="sendEmail" class="form">
        <label for="user_firstname"></label>
        <input
          class="input"
          type="text"
          id="user_firstname"
          name="user_firstname"
          placeholder="Förnamn*"
          required
        />
        <label for="user_lastname"></label>
        <input
          class="input"
          type="text"
          id="user_lastname"
          name="user_lastname"
          placeholder="Efternamn*"
          required
        />

        <label for="user_email"></label>
        <input
          class="input"
          type="email"
          id="user_email"
          name="user_email"
          placeholder="E-post*"
          required
        />
        <label for="user_phone"></label>
        <input
          class="input"
          type="tel"
          id="user_phone"
          name="user_phone"
          pattern="[0-9]{10,15}"
          autocomplete="tel"
          inputmode="tel"
          placeholder="Telefonnummer*"
          required
        />

        <label for="message"></label>
        <textarea
          class="input"
          id="message"
          name="message"
          rows="4"
          placeholder="Meddelande"
          style="font-family: Lato"
        ></textarea>

        <button type="submit">Skicka</button>
      </form>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import emailjs from "@emailjs/browser";
const form = ref(null);
const sendEmail = async () => {
  if (!form.value) return;

  try {
    const result = await emailjs.sendForm(
      "service_8g0u53o",
      "template_ri83pks",
      form.value,
      "3Cy7rKs4oBVsOJ5U8"
    );

    console.log("E-post skickat!", result.text);
    const formElement = document.querySelector(".form");
    if (formElement) {
      formElement.style.display = "none";

      const submitted = document.querySelector(".submitted");
      if (submitted) {
        submitted.style.display = "block";
      }

      setTimeout(() => {
        formElement.style.display = "block";
        if (submitted) {
          submitted.style.display = "none";
        }
      }, 7000);
    }

    form.value.reset();
  } catch (error) {
    console.error("Fel vid e-postskickning:", error.text);
    alert("Något gick fel, försök igen!");
  }
};
</script>
<style lang="scss" scoped>
@import "../assets/variables.scss";
.kontaktaMain {
  .banner {
    img {
      max-width: 100%;
      object-fit: fill;
    }
  }
  .contactWrapper {
    .Contact {
      width: 100%;
      padding: 20px 20px;
      padding-bottom: 0px;
      .contactText {
        p {
          margin: 0;
          padding: 5px;
          font-weight: 600;
        }
        h1 {
          margin: 0;
          padding: 5px;
        }
      }

      .contactInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-bottom: 30px;
        .CIdiv {
          display: flex;
          flex-direction: row;
          padding: 5px;
          align-items: center;
          a {
            color: black;
            &:hover {
              color: $primary;
            }
          }
          img {
            width: 25px;
            padding-right: 10px;
          }
        }
      }
    }
    .submitted {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 20px;
      width: 100%;
      .input {
        width: 100%;
        border: none;
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: white;
        border-radius: 3px;
        //box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
        padding: 7px 0px 5px 3px;
        border: 1px solid rgba(0, 0, 0, 0);
        display: flex;
        align-items: center;

        font-size: 16px;
        &:focus {
          border: 1px solid $primary;
          outline: none;
          // background-color: #ff616f3f;
        }
      }
      button {
        margin: 10px auto 10px auto;
        border: none;

        width: max-content;
        padding: 10px 20px;
        background-color: $primary;
        color: $light;
        font-weight: 800;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .kontaktaMain {
    .banner {
      img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
        object-position: 50% 45%;
      }
    }
    .contactWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      gap: 0px;
      padding: 100px 100px;
      width: 100%;
      .Contact {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .form {
        width: 40%;
        * {
          margin: 5px;
        }

        button {
          border: 1px solid $primary;
          -webkit-transition: color 200ms linear, background-color 200ms linear;
          -ms-transition: color 200ms linear, background-color 200ms linear;
          transition: color 200ms linear, background-color 200ms linear;
          &:hover {
            cursor: pointer;
            background-color: $light;
            color: $primary;
          }
        }
      }
    }
  }
}
</style>
