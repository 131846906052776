<template>
  <div class="HomeMain">
    <div class="IntroHome">
      <div class="BGintro">
        <div
          class="IntroText"
          v-motion-fade-visible-once
          :duration="800"
          :delay="100"
        >
          <span>STAVAB i Staffanstorp</span>
          <h1>Din leverantör inom VA</h1>
          <p>
            Vi har allt inom Vatten och avlopp, och lite till. <br />Ditt behov,
            vår uppgift!
          </p>

          <div class="btns">
            <router-link class="btn" to="/produkter">Se produkter</router-link>
            <router-link class="btn" to="/kontakta">Kontakta oss</router-link>
          </div>
        </div>
      </div>
      <div class="secondDiv">
        <div
          class="secondText"
          v-motion-slide-visible-once-left
          :duration="1500"
          :delay="100"
        >
          <h2>Rör det rör, rör det STAVAB</h2>
          <p>
            STAVAB är en familjeägd VA-grossist som levererar i Skåne med
            Staffanstorp som bas. Sedan 1993 har hög personlig service,
            kvalitetsprodukter och fri leverans varit våra ledord. Letar ni
            efter en trygg och kunnig partner? Då har ni letat klart, kontakta
            oss idag för att få hjälp med just ert projekt.
          </p>
        </div>
        <img
          class="imgE"
          src="../assets/Bilder/thumbnail_IMG_1777.jpg"
          v-motion-slide-visible-once-right
          :duration="1500"
          :delay="100"
        />
      </div>
    </div>
    <div class="tjansterDiv">
      <h2 class="tjansterh1">Våra tjänster</h2>
      <div
        class="tjanster"
        v-motion-fade-visible-once
        :duration="1500"
        :delay="300"
      >
        <div class="tjanst">
          <h3>Snabba leveranser</h3>
          <p class="tjanstP">
            Snabb och <span> fri leverans</span> - ut till projekt eller till
            ert lager. Undvik bekymmer med långa ledtider och krångliga
            leveranser, vi lastar och kör ut efter era önskemål.
          </p>
        </div>
        <div class="tjanst">
          <h3>Kvalitetsprodukter</h3>
          <p class="tjanstP">
            Vi erbjuder endast <span>kvalitetsprodukter</span> från pålitliga
            leverantörer, vilket garanterar hållbara och långsiktiga lösningar.
            Varje produkt i vårt sortiment är noggrant utvald för att möta
            branschens krav och era behov, till bäst pris dessutom!
          </p>
        </div>
        <div class="tjanst">
          <h3>Bästa priser</h3>
          <p class="tjanstP">
            Trots vår <span>höga standard</span> strävar vi alltid efter att
            erbjuda de bästa priserna. Genom smarta inköp och en
            kostnadseffektiv organisation kan vi hålla konkurrenskraftiga priser
            utan att kompromissa med service eller kvalitet.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>
<style lang="scss" scoped>
@import "../assets/variables.scss";

.HomeMain {
  width: 100%;
  .IntroHome {
    .BGintro {
      background-image: url("../assets/Bilder/stavab-slider-3BW2.jpg");
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0px;
      .IntroText {
        color: $light;
        background-color: $tranPrimary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 20px;
        width: 100%;
        h1 {
          font-size: 28px;
        }
        * {
          margin: 10px;
        }
        .btns {
          width: 100%;
          display: flex;
          flex-direction: row;

          justify-content: space-evenly;
          .btn {
            background-color: $dark;
            padding: 10px 20px;
            text-decoration: none;
            color: $light;
          }
        }
      }
    }
    .secondDiv {
      padding: 0px 20px;

      img {
        display: none;
      }
      p {
        max-width: 80ch;
      }
    }
  }
  .tjansterDiv {
    padding-top: 20px;
    .tjansterh1 {
      text-align: center;
    }
    .tjanster {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: stretch;

      .tjanterh1 {
        padding: 10px 20px;
      }
      .tjanst {
        padding: 10px 30px;
        background-color: $dark;
        color: $light;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 100%;
        height: 100%;

        h2 {
          font-weight: 600;
        }
        p {
          max-width: 80ch;
          span {
            font-weight: 800;
          }
        }
      }
      .tjanst:nth-child(2) {
        background-color: $light;
        color: black;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .HomeMain {
    width: 100%;
    .IntroHome {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-bottom: 50px;
      background-color: $light;
      .BGintro {
        background-size: cover;
        background-position: center;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 100px;

        height: 88vh;
        .IntroText {
          width: fit-content;
          padding-right: 80px;

          span {
            font-weight: 400;
            font-size: 18px;
          }
          h1 {
            font-size: 2.5rem;
          }

          .btns {
            .btn {
              -webkit-transition: background-color 200ms linear;
              -ms-transition: background-color 200ms linear;
              transition: background-color 200ms linear;

              &:hover {
                background-color: rgba(0, 0, 0, 0);
              }
            }
          }
        }
      }
      .secondDiv {
        display: flex;
        margin-top: 40px;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        padding-right: 90px;
        .imgE {
          display: block;
          aspect-ratio: 1.5/1;
          object-fit: cover;

          width: 30%;
        }
        .secondText {
          padding: 20px;
          padding-left: 90px;
          width: 40%;
        }
      }
    }
    .tjansterDiv {
      background-color: white;
      padding-bottom: 100px;
      .tjanster {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: stretch;
        padding: 0px 100px;
        .tjanst {
          display: flex;
          flex-direction: column;

          background-color: $light;
          color: black;
          width: 80%;
          margin: 0 auto;

          h3 {
            color: $primary;
          }
          p {
            max-width: 80ch;
          }
        }
      }
    }
  }
}
</style>
