<template>
  <div class="FooterMain">
    <div class="Fcontainer">
      <img src="@/assets/Bilder/stavab-logo.png" />
      <div class="upperFooter">
        <p>STAVAB Staffanstorps VA-lager AB</p>
        <a
          class="adress"
          href="https://maps.app.goo.gl/5ejhJssx44EH8Xxu7"
          target="_blank"
          >Fabriksvägen 9, 245 34 Staffanstorp</a
        >
      </div>
      <div class="lowerFooter">
        <p>Orgnr: 556902-0984</p>
        <p>Tel: <a href="tel:046-25 55 80">046-25 55 80</a></p>
        <p>Mail: <a href="mailto:info@stavab.se">info@stavab.se</a></p>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.FooterMain {
  flex: 1;
  border-top: 2px solid $dark;
  padding-bottom: 30px;
  background-color: $light;
  .Fcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
    }
    * {
      margin: 0;
    }
    .upperFooter {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .lowerFooter {
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    a {
      color: black;
    }
  }
}
@media screen and (min-width: 768px) {
  .FooterMain {
    flex: 1;
    .Fcontainer {
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;
      img {
        width: 10%;
      }
      .upperFooter {
        flex-direction: row;
        gap: 40px;
      }
      .lowerFooter {
        flex-direction: row;
        gap: 60px;
      }
      a {
        -webkit-transition: color 200ms linear, background-color 200ms linear;
        -ms-transition: color 200ms linear, background-color 300ms linear;
        transition: color 200ms linear, background-color 200ms linear;
        &:hover {
          color: $secondary;
        }
      }
    }
  }
}
</style>
